import React, {useState} from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import {CSSTransition} from 'react-transition-group'
import Image from 'next/image'
import Link from 'next/link'
import {useRouter} from 'next/router'
import GenericLink from '../../shared/Link'
import FunctionalNavigation from './FunctionalNavigation'
import NavigationHighlight from './NavigationHighlight'
import ConditionalWrapper from '../../shared/ConditionalWrapper'
import Search from './Search'
import logo from '../../../public/SDWA_logo_new_adapted.svg'
import MediaItem from '../../shared/MediaItem'
import {MenuLink} from '../../../types/content-types'
import type {Response} from '../../../types/response'
import LanguageSwitch from './LanguageSwitch'

type Props = {
  mainMenuItems: Omit<Response, 'data' | 'meta' | 'links'> | null
  functionalNavItems?: Omit<Response, 'data' | 'meta' | 'links'> | null
  languageSwitchMenuItems?: Omit<Response, 'data' | 'meta' | 'links'> | null
  response?: Omit<Response, 'data' | 'meta' | 'links'> | null
  expanded: boolean
  // eslint-disable-next-line no-unused-vars
  setExpanded: (expandedState: boolean) => void
  // eslint-disable-next-line no-unused-vars
  setShadow?: (val: boolean) => void
}

const MainNavigation = ({
  mainMenuItems,
  functionalNavItems,
  expanded,
  languageSwitchMenuItems,
  response,
  setExpanded,
  setShadow,
}: Props) => {
  const [activeMenu, setActiveMenu] = useState('main')
  const [activeSubNav, setActiveSubNav] = useState('')
  const router = useRouter()
  const defaultLanguageSwitchMenuItem = languageSwitchMenuItems?.included?.find(
    el => el?.attributes?.field_default_language,
  )
  const languageSwitchMenuItemsWithoutDefault =
    languageSwitchMenuItems?.included?.filter(
      el => !el?.attributes?.field_default_language,
    )

  React.useEffect(() => {
    const handleRouteChange = () => {
      setActiveSubNav('')
      setActiveMenu('main')
      setExpanded(false)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, setExpanded])
  const mainMenuItemsFull = mainMenuItems?.included?.filter(
    el => el.type === 'menu_link_content--main',
  ) as MenuLink[] | undefined
  const functionalNavItemsFull = functionalNavItems?.included?.filter(
    el => el.type === 'menu_link_content--functional',
  ) as MenuLink[] | undefined
  const mainMenuItemsFiltered = mainMenuItemsFull?.filter(
    el => el.attributes.enabled,
  )
  const topLevelMainMenuItems = mainMenuItemsFiltered?.filter(
    el => !el.attributes.parent,
  )

  const handleMenuItemClick = (menuItem: MenuLink | null = null) => {
    if (menuItem && menuItem.children) {
      setActiveMenu(menuItem.id)
    } else {
      setActiveMenu('main')
      setExpanded(false)
    }
  }

  const handleToggleClick = () => {
    setActiveMenu('main')
    setExpanded(!expanded)
  }

  const handleMenuItemHoverOn = (item: any[] | undefined) => {
    if (item && item.length > 0) {
      setShadow?.(true)
    } else {
      setShadow?.(false)
    }
  }

  const renderNavigationItem = (
    navigationItem: MenuLink & {mediaImage?: MediaImage},
    withWrapper: boolean = false,
  ) => {
    const isLanguageSwitch =
      navigationItem?.type === 'menu_link_content--language-switch'
    const enabledChildren = isLanguageSwitch
      ? languageSwitchMenuItemsWithoutDefault
      : navigationItem.children?.filter(el => el.attributes.enabled)

    return (
      <ConditionalWrapper
        condition={withWrapper}
        wrapper={(childrenPassed: any) => (
          <div className="c-navbar__subnav-mobile__innerWrapper d-flex justify-content-between w-full">
            {childrenPassed}
          </div>
        )}
        key={navigationItem.id}
      >
        {navigationItem.attributes.imageSrc ? (
          <Image
            src={
              navigationItem.attributes.imageSrc.indexOf(
                process.env.NEXT_PUBLIC_API_URL!,
              ) < 0
                ? `${process.env.NEXT_PUBLIC_API_URL}${navigationItem.attributes.imageSrc}`
                : navigationItem.attributes.imageSrc
            }
            className="d-flex mr-3"
            alt={navigationItem.attributes.imageAlt ?? ''}
            layout="fixed"
            width={isLanguageSwitch ? 34 : 32}
            height={isLanguageSwitch ? 20 : 32}
          ></Image>
        ) : null}
        <Nav.Item
          className={
            enabledChildren && enabledChildren.length > 0 ? 'hasChildren' : ''
          }
          key={navigationItem.id}
          onClick={() => {
            handleMenuItemClick(navigationItem)
          }}
        >
          <GenericLink extraClass="ma" link={navigationItem.attributes.link}>
            {navigationItem.attributes.title}
          </GenericLink>
        </Nav.Item>
      </ConditionalWrapper>
    )
  }

  const renderMainNavigation = (menuItem: MenuLink) => {
    const isLanguageSwitch =
      menuItem?.type === 'menu_link_content--language-switch'
    const enabledChildren = isLanguageSwitch
      ? languageSwitchMenuItemsWithoutDefault
      : mainMenuItemsFiltered?.filter(el =>
          el.attributes.parent?.includes(menuItem.id),
        )
    const wrapGroup = !enabledChildren?.some(child =>
      mainMenuItemsFiltered?.some(el =>
        el.attributes.parent?.includes(child.id),
      ),
    )
    return (
      <Nav
        className={`c-navbar__subnav ${
          menuItem.id === activeSubNav ? 'active' : ''
        }`}
        key={menuItem.id}
      >
        <div className="d-flex flex-column">
          <div className="c-navbar__subnav-header">
            {menuItem.attributes.description}
          </div>
          <ConditionalWrapper
            condition={wrapGroup}
            wrapper={(childrenPassed: any) => (
              <div className="c-navbar__subnav-groupWrapper">
                {childrenPassed}
              </div>
            )}
          >
            {enabledChildren
              ?.sort((a, b) => a.attributes.weight! - b.attributes.weight!)
              ?.map(firstLevel => {
                const enabledGrandChildren = mainMenuItemsFiltered?.filter(el =>
                  el.attributes.parent?.includes(firstLevel.id),
                )
                return (
                  <React.Fragment key={firstLevel.id}>
                    <div className="c-navbar__subnav-group d-flex">
                      {renderNavigationItem(firstLevel)}
                      {enabledGrandChildren
                        ?.sort(
                          (a, b) => a.attributes.weight! - b.attributes.weight!,
                        )
                        .map(secondLevel => renderNavigationItem(secondLevel))}
                    </div>
                  </React.Fragment>
                )
              })}
          </ConditionalWrapper>
        </div>
        <NavigationHighlight
          response={mainMenuItems}
          highlights={menuItem.relationships?.field_navigation_highlight?.data}
        />
      </Nav>
    )
  }

  const renderMobileNavigation = (
    childrenForMobile: MenuLink,
    parentId: string,
  ) => {
    const isLanguageSwitch =
      childrenForMobile?.type === 'menu_link_content--language-switch'
    const enabledChildren = isLanguageSwitch
      ? languageSwitchMenuItemsWithoutDefault
      : mainMenuItemsFiltered?.filter(el =>
          el.attributes.parent?.includes(childrenForMobile.id),
        )

    return (
      <CSSTransition
        in={activeMenu === childrenForMobile.id}
        timeout={250}
        classNames="c-navbar__menu-slide"
        unmountOnExit
        key={`${parentId}-${childrenForMobile.id}`}
      >
        <div>
          <Nav className="c-navbar__subnav-mobile">
            {!!childrenForMobile && (
              <Nav.Item className="c-navbar__subnav-mobile-title">
                <button
                  onClick={() => setActiveMenu(parentId)}
                  aria-label="Back to parent"
                  type="button"
                />
                {childrenForMobile.attributes.title}
              </Nav.Item>
            )}
            <div className="c-navbar__subnav-mobile-group">
              {enabledChildren
                ?.sort((a, b) => a.attributes.weight! - b.attributes.weight!)
                .map(child => renderNavigationItem(child, true))}
            </div>
          </Nav>

          <NavigationHighlight
            response={mainMenuItems}
            highlights={
              childrenForMobile.relationships?.field_navigation_highlight?.data
            }
          />
        </div>
      </CSSTransition>
    )
  }

  return (
    <Navbar
      expand="lg"
      variant="light"
      bg="white"
      className="c-navbar"
      collapseOnSelect
      onSelect={() => {
        setActiveMenu('main')
      }}
      expanded={expanded}
    >
      <Link href="/" passHref>
        <Navbar.Brand className="ma">
          <Image width={152} height={31} alt="logo" src={logo} priority />
        </Navbar.Brand>
      </Link>

      <Navbar.Toggle
        aria-controls="navbar-nav"
        className="close-btn"
        onClick={() => handleToggleClick()}
      ></Navbar.Toggle>
      <Navbar.Collapse id="navbar-nav">
        <div className="c-navbar__nav-desktop">
          <Nav className="mr-auto h-100 align-items-center">
            {topLevelMainMenuItems
              ?.sort((a, b) => a.attributes.weight! - b.attributes.weight!)
              .map(menuItem => {
                const enabledChildren = mainMenuItemsFiltered?.filter(el =>
                  el.attributes.parent?.includes(menuItem.id),
                )
                return (
                  <Nav.Item
                    className={`test ${
                      enabledChildren && enabledChildren.length! > 0
                        ? 'hasChildren'
                        : ''
                    }
                  `}
                    key={menuItem.id}
                    onMouseEnter={() => {
                      setActiveSubNav(menuItem.id)
                      handleMenuItemHoverOn(enabledChildren)
                    }}
                    onMouseLeave={() => {
                      setActiveSubNav('')
                      setShadow?.(false)
                    }}
                    onFocus={() => setActiveSubNav(menuItem.id)}
                    onBlur={() => setActiveSubNav('')}
                  >
                    <GenericLink
                      extraClass={`ma ${
                        menuItem.id === activeSubNav ? 'active' : ''
                      }`}
                      link={menuItem.attributes.link}
                    >
                      {menuItem.attributes.title}
                    </GenericLink>
                    {enabledChildren && enabledChildren.length! > 0 && (
                      <button
                        onClick={() => {
                          setActiveMenu(menuItem.id)
                        }}
                        type="button"
                        className="c-navbar__nav-mobile--subNavBtn"
                        aria-label="Open subnavigation"
                      >
                        +
                      </button>
                    )}
                    {enabledChildren &&
                      enabledChildren.length > 0 &&
                      renderMainNavigation(menuItem)}
                  </Nav.Item>
                )
              })}
          </Nav>
          {functionalNavItems && (
            <FunctionalNavigation functionalNavItems={functionalNavItemsFull} />
          )}
          {languageSwitchMenuItems ? (
            <LanguageSwitch languageSwitchMenuItems={languageSwitchMenuItems} />
          ) : null}
        </div>
        <CSSTransition
          in={activeMenu === 'main'}
          timeout={500}
          classNames="c-navbar__menu-slide"
          unmountOnExit
        >
          <div className="c-navbar__nav-mobile">
            <Search
              isMobile
              searchItem={functionalNavItemsFull?.find(
                item => item.attributes.field_functional_type === 'search',
              )}
            />
            <Nav className="mr-auto h-100 align-items-center">
              {topLevelMainMenuItems
                ?.sort((a, b) => a.attributes.weight! - b.attributes.weight!)
                ?.concat(defaultLanguageSwitchMenuItem)
                ?.filter(Boolean)
                .map(menuItem => {
                  const isLanguageSwitch =
                    menuItem?.type === 'menu_link_content--language-switch'
                  const enabledChildren = isLanguageSwitch
                    ? languageSwitchMenuItemsWithoutDefault
                    : mainMenuItemsFiltered?.filter(el =>
                        el.attributes.parent?.includes(menuItem.id),
                      )

                  return (
                    <Nav.Item
                      className={
                        enabledChildren && enabledChildren.length! > 0
                          ? 'hasChildren'
                          : ''
                      }
                      key={`csst-${menuItem.id}`}
                    >
                      <div className="c-navbar__nav-mobile__innerWrapper d-flex justify-content-between w-full">
                        {isLanguageSwitch ? (
                          <span className="d-flex flex-row align-items-center c-navbar__nav-mobile__innerWrapper--lang-switch">
                            {(menuItem as any)?.mediaImage ? (
                              <MediaItem
                                item={(menuItem as any)?.mediaImage}
                                response={response}
                                width={34}
                                height={20}
                              />
                            ) : null}
                            {menuItem.attributes.title}
                          </span>
                        ) : (
                          <GenericLink
                            extraClass="ma"
                            link={menuItem.attributes.link}
                          >
                            {menuItem.attributes.title}
                          </GenericLink>
                        )}
                        {enabledChildren && enabledChildren.length > 0 && (
                          <button
                            onClick={() => {
                              setActiveMenu(menuItem.id)
                            }}
                            type="button"
                            className="c-navbar__nav-mobile--subNavBtn"
                            aria-label="Open subnavigation"
                          >
                            +
                          </button>
                        )}
                      </div>
                      {enabledChildren && renderMainNavigation(menuItem)}
                    </Nav.Item>
                  )
                })}
            </Nav>
            {functionalNavItems && (
              <FunctionalNavigation
                functionalNavItems={functionalNavItemsFull}
              />
            )}
          </div>
        </CSSTransition>
        {topLevelMainMenuItems
          ?.sort((a, b) => a.attributes.weight! - b.attributes.weight!)
          .map(child => {
            const enabledChildren = mainMenuItemsFiltered?.filter(el =>
              el.attributes.parent?.includes(child.id),
            )
            if (enabledChildren && enabledChildren.length > 0) {
              return renderMobileNavigation(child, 'main')
            }
            return null
          })}
        {defaultLanguageSwitchMenuItem
          ? renderMobileNavigation(defaultLanguageSwitchMenuItem, 'main')
          : null}
        {topLevelMainMenuItems
          ?.sort((a, b) => a.attributes.weight! - b.attributes.weight!)
          .map(topLevelItem => {
            const enabledChildren = mainMenuItemsFiltered?.filter(el =>
              el.attributes.parent?.includes(topLevelItem.id),
            )
            return enabledChildren?.map(child => {
              const enabledGrandChildren = mainMenuItemsFiltered?.filter(el =>
                el.attributes.parent?.includes(child.id),
              )
              return enabledGrandChildren?.map(grandChild =>
                renderMobileNavigation(grandChild, topLevelItem.id),
              )
            })
          })}
      </Navbar.Collapse>
    </Navbar>
  )
}

export default MainNavigation
