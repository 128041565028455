import React, {useCallback} from 'react'
import Button from 'react-bootstrap/Button'
import Nav from 'react-bootstrap/Nav'
import {MenuLink} from '../../../types/content-types'
import GenericLink from '../../shared/Link'
import Search from './Search'

type Props = {
  functionalNavItems?: MenuLink[]
}

const FunctionalNavigation = ({functionalNavItems}: Props) => {
  const renderSearch = useCallback(() => {
    return (
      <Search
        searchItem={functionalNavItems?.find(
          item => item.attributes.field_functional_type === 'search',
        )}
      />
    )
  }, [functionalNavItems])

  return (
    <Nav className="c-navbar__functional-nav h-100 align-items-center">
      {functionalNavItems
        ?.filter(
          item =>
            item.attributes.field_functional_type === 'login' &&
            item.attributes.enabled,
        )
        .map(item => (
          <Nav.Item className="c-navbar__functional-nav-login" key={item.id}>
            <GenericLink extraClass="ma" link={item.attributes.link}>
              {item.attributes.title}
            </GenericLink>
          </Nav.Item>
        ))}
      {renderSearch()}
      {functionalNavItems
        ?.filter(
          item =>
            item.attributes.field_functional_type === 'button' &&
            item.attributes.enabled,
        )
        .sort((a, b) => a.attributes.weight! - b.attributes.weight!)
        .map(item => (
          <Button
            className="btn--red c-navbar__functional-nav-button--orange border-0"
            key={item.id}
          >
            <GenericLink extraClass="ma text-white" link={item.attributes.link}>
              {item.attributes.title}
            </GenericLink>
          </Button>
        ))}
    </Nav>
  )
}

export default FunctionalNavigation
